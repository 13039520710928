import {
  faBriefcase,
  faBullhorn,
  faCalendarAlt,
  faCertificate,
  faCogs,
  faEnvelope,
  faFileUpload,
  faGenderless,
  faGraduationCap,
  faHeart,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "react-bootstrap";
import "./NewCampaign.css";

const HRSCandidateData = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null); // Add state for DatePicker
  const [formData, setFormData] = useState({
    name: "",
    position: "",
    mobileNumber: "",
    alternateMobileNumber: "",
    email: "",
    dateOfBirth: "",
    gender: "",
    maritalStatus: "",
    highestEducationQualification: "",
    experience: "",
    topThreeSkills: "",
    certificate: "",
    howDidYouHearAboutUs: "",
    resume: null,
    consent: false,
  });

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("HRSCandidateCard");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleItemsClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log(formData);
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Candidate Application
              </h5>
            </div>
          </div>
          <Card className="card-style mt-3">
            <Card.Body>
              <Row>
                <Col>
                  <form onSubmit={handleSubmit}>
                    <h4 className="text-primary mb-4 mx-3">
                      Candidate Application Form
                    </h4>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Name of Candidate{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                            <Form.Control
                              type="text"
                              name="candidate_name"
                              value={formData.candidate_name}
                              onChange={handleChange}
                              required
                              placeholder="Enter Your Full Name"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Position <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faBriefcase} />
                            </span>
                            <Form.Control
                              type="text"
                              name="position"
                              value={formData.position}
                              onChange={handleChange}
                              required
                              placeholder="Position Applying For"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Mobile Number <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <Form.Control
                              type="tel"
                              name="mobile"
                              value={formData.mobile}
                              onChange={handleChange}
                              required
                              placeholder="Enter Mobile Number"
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Alternate Mobile Number
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faPhone} />
                            </span>
                            <Form.Control
                              type="tel"
                              name="alternate_mobile"
                              value={formData.alternate_mobile}
                              onChange={handleChange}
                              placeholder="Enter Alternate Mobile Number"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Email <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                            <Form.Control
                              type="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                              required
                              placeholder="Enter Email Address"
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Date of Birth <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span
                              className="input-group-text"
                              style={{ width: "7%" }}
                            >
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </span>
                            <DatePicker
                              selected={selectedDate}
                              onChange={(date) => setSelectedDate(date)}
                              style={{ width: "93%" }}
                              className="form-control"
                              dateFormat="dd-MM-yyyy"
                              placeholderText="Select Date"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      {/* Gender field */}
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Gender <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                            <select
                              className="form-control form-select"
                              name="gender"
                              value={formData.gender}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Select Gender</option>
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                        </Form.Group>
                      </Col>

                      {/* Marital Status field */}
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Marital Status{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                            <select
                              className="form-control form-select"
                              name="marital_status"
                              value={formData.marital_status}
                              onChange={handleChange}
                              required
                            >
                              <option value="">Select Marital Status</option>
                              <option value="Single">Single</option>
                              <option value="Married">Married</option>
                              <option value="Divorced">Divorced</option>
                            </select>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Highest Educational Qualification{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faGraduationCap} />
                            </span>
                            <Form.Control
                              type="text"
                              name="qualification"
                              value={formData.qualification}
                              onChange={handleChange}
                              required
                              placeholder="Enter Your Qualification"
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Experience (Years){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faBriefcase} />
                            </span>
                            <Form.Control
                              type="number"
                              name="experience"
                              value={formData.experience}
                              onChange={handleChange}
                              required
                              placeholder="Years of Experience"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Top Three Skills{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faCogs} />
                            </span>
                            <Form.Control
                              type="text"
                              name="skills"
                              value={formData.skills}
                              onChange={handleChange}
                              required
                              placeholder="Enter Your Top Skills"
                            />
                          </div>
                        </Form.Group>
                      </Col>

                      <Col md="6" className="mb-3">
                        <Form.Group>
                          <label className="label-style">Certifications</label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faCertificate} />
                            </span>
                            <Form.Control
                              type="text"
                              name="certifications"
                              value={formData.certifications}
                              onChange={handleChange}
                              placeholder="Enter Relevant Certifications"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            How did you hear about us?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faBullhorn} />
                            </span>
                            <Form.Control
                              type="text"
                              name="how_heard"
                              value={formData.how_heard}
                              onChange={handleChange}
                              required
                              placeholder="How did you hear about this opportunity?"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Group>
                          <label className="label-style">
                            Upload Resume <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <FontAwesomeIcon icon={faFileUpload} />
                            </span>
                            <Form.Control
                              className="form-control file-input-container"
                              type="file"
                              name="resume"
                              onChange={handleChange}
                              required
                              accept=".pdf,.doc,.docx"
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12" className="mb-3">
                        <Form.Group className="d-flex align-items-center">
                          <Form.Check
                            type="checkbox"
                            name="consent"
                            checked={formData.consent}
                            onChange={handleChange}
                            required
                            id="consent-checkbox"
                          />
                          <label
                            className="ms-2 label-style"
                            htmlFor="consent-checkbox"
                          >
                            I consent to the processing of my personal data for
                            recruitment purposes
                          </label>
                        </Form.Group>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        variant="outline-secondary"
                        className="mx-2"
                        onClick={handleCancleClick}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="mx-2"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit Application
                      </Button>
                    </div>
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HRSCandidateData;
