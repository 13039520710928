/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import newzerosblackmainmenu from "../../asset/images/jpslogo.png";
import Mainmenu from "./Mainmenu";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../../screens/Dashboard/CommonApi";

function Leftbar(props) {
  const { navigation } = props;
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const [itemData, setItemData] = useState({
    organizationName: "",
    companyName: "",
  });
  const apiUrl = BASE_URL + `/fetchUserProfile`;
  const jwtToken = localStorage.getItem("jwtToken");
  const userId = localStorage.getItem("userId");
  const fetchData = () => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({ uniqueId: decryptedUniqueId, userId: userId }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          const portfolioItem = data.responseMessage;
          setItemData({
            organizationName: portfolioItem.organizationName,
            companyName: portfolioItem.companyName,
            uniqueId: portfolioItem.uniqueId,
          });
        } else {
          console.error("Error fetching portfolio data");
        }
      })
      .catch((error) => {
        console.error("Error fetching portfolio data:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  // Function to convert string to title case
  let toTitleCase = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  return (
    <div className="sidebar text-dark" style={{ backgroundColor: "#ffffff" }}>
      <div className="sidebar-scroll">
        <div className="user-account">
          <img
            src={newzerosblackmainmenu}
            alt="User Profile Picture"
            style={{ width: "60%" }}
          />
          {/* <hr /> */}
          {/* <h6 className="mb-3 text-dark text-center mt-2">
            Hi, {titleCaseCompanyName}
          </h6> */}
          {/* <ul className="row mt-2 list-unstyled">
            <li className="col-4">
              <div className="pointer borderrr">
                <small>Invoices</small>
                <h6 className="mt-1">
                  <strong>{invoiceCount}</strong>
                </h6>
              </div>
            </li>
            <li className="col-4 text-center">
              <div className="pointer borderrr">
                <small>Customers</small>
                <h6 className="mt-1">
                  <strong>{customerCount}</strong>
                </h6>
              </div>
            </li>
            <li className="col-4">
              <div className="pointer borderrr">
                <small>Revenue</small>
                <h6 className="mt-1">
                  <strong>0</strong>
                </h6>
              </div>
            </li>
          </ul> */}
        </div>

        <Mainmenu navigation={navigation} />
      </div>
    </div>
  );
}

export default Leftbar;
