import React, { useState } from "react";
import { Row, Col, Form, Card, Button, Container } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../asset/vendor/font-awesome/css/font-awesome.min.css";
import "./NewCampaign.css";
import {
  faUser,
  faBuilding,
  faBriefcase,
  faCalendarAlt,
  faPhone,
  faEnvelope,
  faTint,
  faDollarSign,
  faIdCard,
  faCreditCard,
  faHeart,
  faUsers,
  faFile,
  faHome,
  faFileAlt,
  faGraduationCap,
  faIndianRupeeSign,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";

const HRSEmployee = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    employee_name: "",
    department: "",
    designation: "",
    date_of_joining: "",
    contact_number: "",
    alternate_contact: "",
    personal_email: "",
    dob: "",
    blood_group: "",
    salary: "",
    aadhar_card: null,
    pan_card: null,
    spouse_name: "",
    parent_name: "",
    bank_passbook: null,
    current_address: "",
    permanent_address: "",
    address_proof: null,
    highest_qualification: "",
    marksheets_pg: null,
    marksheets_grad: null,
    marksheets_12: null,
    certificates: null,
    previous_company: "",
    previous_designation: "",
    experience_letter: null,
    confirmation: false,
  });

  const [selectedDate, setSelectedDate] = useState(null); // Add state for DatePicker

  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("HRSEmployeeCard");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleItemsClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add your form submission logic here
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                Employee Application
              </h5>
            </div>
          </div>
          <Card className="card-style">
            <Card.Body>
              <form onSubmit={handleSubmit}>
                {/* Previous sections remain the same until Address Information */}
                <h4 className="text-primary mb-4">Employee Data Form</h4>

                {/* Personal Information Section */}
                <h5 className="text-secondary mb-3">Personal Information</h5>
                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Employee Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faUser} />
                        </span>
                        <Form.Control
                          type="text"
                          name="employee_name"
                          value={formData.employee_name}
                          onChange={handleChange}
                          required
                          placeholder="Enter Employee name"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Department <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faBuilding} />
                        </span>
                        <Form.Control
                          type="text"
                          name="department"
                          value={formData.department}
                          onChange={handleChange}
                          required
                          placeholder="Enter Department"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Designation <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faBriefcase} />
                        </span>
                        <Form.Control
                          type="text"
                          name="designation"
                          value={formData.designation}
                          onChange={handleChange}
                          required
                          placeholder="Enter Designation"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Date of Joining <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "7%" }}
                        >
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          style={{ width: "93%" }}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Select Date"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Contact Information */}
                <h5 className="text-secondary mb-3 mt-4">
                  Contact Information
                </h5>
                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Contact Number <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faPhone} />
                        </span>
                        <Form.Control
                          type="tel"
                          name="contact_number"
                          value={formData.contact_number}
                          onChange={handleChange}
                          required
                          placeholder="Enter Contact Number"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Alternate Contact <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faPhone} />
                        </span>
                        <Form.Control
                          type="tel"
                          name="alternate_contact"
                          value={formData.alternate_contact}
                          onChange={handleChange}
                          required
                          placeholder="Enter Alternate Contact"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Personal Details */}
                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Personal Email <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                        <Form.Control
                          type="email"
                          name="personal_email"
                          value={formData.personal_email}
                          onChange={handleChange}
                          required
                          placeholder="Enter Personal Email"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Date of Birth <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span
                          className="input-group-text"
                          style={{ width: "7%" }}
                        >
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </span>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          style={{ width: "93%" }}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Select Date"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Blood Group <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faTint} />
                        </span>
                        <Form.Control
                          type="text"
                          name="blood_group"
                          value={formData.blood_group}
                          onChange={handleChange}
                          required
                          placeholder="Enter Blood Group"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Salary <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faIndianRupeeSign} />
                        </span>
                        <Form.Control
                          type="number"
                          name="salary"
                          value={formData.salary}
                          onChange={handleChange}
                          required
                          placeholder="Enter Salary"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Document Uploads Section */}
                <h5 className="text-secondary mb-3 mt-4">Document Uploads</h5>
                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Aadhar Card <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faIdCard} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="aadhar_card"
                          onChange={handleChange}
                          required
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        PAN Card <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faCreditCard} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="pan_card"
                          onChange={handleChange}
                          required
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Family Information */}
                <h5 className="text-secondary mb-3 mt-4">Family Information</h5>
                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">Spouse Name</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faHeart} />
                        </span>
                        <Form.Control
                          type="text"
                          name="spouse_name"
                          value={formData.spouse_name}
                          onChange={handleChange}
                          placeholder="Enter Spouse Name"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Parent Name <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faUsers} />
                        </span>
                        <Form.Control
                          type="text"
                          name="parent_name"
                          value={formData.parent_name}
                          onChange={handleChange}
                          required
                          placeholder="Enter Parent Name"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Bank Details */}
                <h5 className="text-secondary mb-3 mt-4">Bank Details</h5>
                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Bank Passbook/Cheque{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFile} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="bank_passbook"
                          onChange={handleChange}
                          required
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Address Information */}
                <h5 className="text-secondary mb-3 mt-4">
                  Address Information
                </h5>
                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Current Address <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faHome} />
                        </span>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          className="form-control"
                          name="current_address"
                          value={formData.current_address}
                          onChange={handleChange}
                          required
                          placeholder="Enter Current Address"
                          style={{
                            minHeight: "100px",
                            resize: "vertical",
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Permanent Address <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faHome} />
                        </span>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          name="permanent_address"
                          className="form-control"
                          value={formData.permanent_address}
                          onChange={handleChange}
                          required
                          placeholder="Enter Permanent Address"
                          style={{
                            minHeight: "100px",
                            resize: "vertical",
                          }}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Address Proof <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFileAlt} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="address_proof"
                          onChange={handleChange}
                          required
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                {/* Educational Information */}
                <h5 className="text-secondary mb-3 mt-4">
                  Educational Information
                </h5>
                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Highest Qualification{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faGraduationCap} />
                        </span>
                        <Form.Control
                          type="text"
                          name="highest_qualification"
                          value={formData.highest_qualification}
                          onChange={handleChange}
                          required
                          placeholder="Enter Highest Qualification"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Post Graduation Marksheets
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="marksheets_pg"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Graduation/Diploma Marksheets
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="marksheets_grad"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        12th/Diploma Marksheets
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="marksheets_12"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Additional Certificates
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="certificates"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Previous Employment */}
                <h5 className="text-secondary mb-3 mt-4">
                  Previous Employment
                </h5>
                <Row>
                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Previous Company Name
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faBuilding} />
                        </span>
                        <Form.Control
                          type="text"
                          name="previous_company"
                          value={formData.previous_company}
                          onChange={handleChange}
                          placeholder="Enter Previous Company Name"
                        />
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md="6" className="mb-3">
                    <Form.Group>
                      <label className="label-style">
                        Previous Designation
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faBriefcase} />
                        </span>
                        <Form.Control
                          type="text"
                          name="previous_designation"
                          value={formData.previous_designation}
                          onChange={handleChange}
                          placeholder="Enter Previous Designation"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Group>
                      <label className="label-style">Experience Letter</label>
                      <div className="input-group">
                        <span className="input-group-text">
                          <FontAwesomeIcon icon={faFile} />
                        </span>
                        <Form.Control
                          type="file"
                          className="form-control file-input-container"
                          name="experience_letter"
                          onChange={handleChange}
                          accept=".pdf,.jpg,.jpeg,.png"
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Confirmation */}
                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Group>
                      <div className="form-check">
                        <Form.Check
                          type="checkbox"
                          name="confirmation"
                          id="confirmation"
                          checked={formData.confirmation}
                          onChange={handleChange}
                          label="I confirm that the information provided is accurate and up-to-date."
                          required
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                {/* Submit Button */}
                <Row>
                  <Col md="12">
                    <div className="d-flex justify-content-end mt-4">
                      <Button
                        variant="outline-secondary"
                        className="mx-2"
                        onClick={handleCancleClick}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="mx-2"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Submit Application
                      </Button>
                    </div>
                  </Col>
                </Row>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HRSEmployee;
