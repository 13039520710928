import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";
import { MessageCircle } from "iconic-react";
import { WhatsappLogo } from "phosphor-react";
import { Mail } from "@mui/icons-material";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewCampaign = ({ navigation }) => {
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with current date

  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [communicationMethods, setCommunicationMethods] = useState({
    whatsapp: false,
    sms: false,
    email: false,
  });
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleItemsClick = () => {
    handleNavigation("CRMCard");
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure Leave this page?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleItemsClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit");
  };

  const [fileBlocks, setFileBlocks] = useState([
    { id: Date.now(), file: null, preview: null, name: null },
  ]);

  const handleAddFileBlock = () => {
    setFileBlocks([
      ...fileBlocks,
      { id: Date.now(), file: null, preview: null, name: null },
    ]);
  };

  const handleRemoveFileBlock = (id) => {
    setFileBlocks(fileBlocks.filter((block) => block.id !== id));
  };

  const handleFileChange = (id, file) => {
    if (!file) return;

    const fileType = file.type;
    const fileName = file.name;

    if (fileType.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileBlocks((blocks) =>
          blocks.map((block) =>
            block.id === id
              ? { ...block, file, preview: e.target.result, name: null }
              : block
          )
        );
      };
      reader.readAsDataURL(file);
    } else if (fileType === "application/pdf") {
      setFileBlocks((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? { ...block, file, preview: null, name: fileName }
            : block
        )
      );
    } else {
      setFileBlocks((blocks) =>
        blocks.map((block) =>
          block.id === id
            ? { ...block, file, preview: null, name: "Unsupported file type" }
            : block
        )
      );
    }
  };

  const customers = [
    { id: 1, name: "Customer Name 1" },
    { id: 2, name: "Customer Name 2" },
    { id: 3, name: "Customer Name 3" },
    { id: 4, name: "Customer Name 4" },
    { id: 5, name: "Customer Name 5" },
    { id: 6, name: "Customer Name 6" },
    { id: 7, name: "Customer Name 7" },
    { id: 8, name: "Customer Name 8" },
    { id: 9, name: "Customer Name 9" },
    { id: 10, name: "Customer Name 10" },
  ];

  const customerOptions = customers.map((customer) => ({
    value: customer.id,
    label: customer.name,
  }));

  // Update the handleCustomerSelect function to work with react-select
  const handleCustomerSelect = (option) => {
    if (option) {
      const customer = customers.find((c) => c.id === option.value);
      if (customer && !selectedCustomers.find((c) => c.id === customer.id)) {
        setSelectedCustomers([...selectedCustomers, customer]);
      }
    }
  };

  const removeCustomer = (customerId) => {
    setSelectedCustomers(selectedCustomers.filter((c) => c.id !== customerId));
  };

  const handleCheckboxChange = (customerId) => {
    setSelectedCustomers((prev) => ({
      ...prev,
      [customerId]: !prev[customerId],
    }));
  };

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#F6F6F6",
      borderRadius: "7px",
      height: "46px",
      border: "1px solid #ced4da",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #ced4da",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      height: "19px",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#6c757d",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#007bff" : "white",
      "&:hover": {
        backgroundColor: state.isSelected ? "#007bff" : "#f8f9fa",
      },
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "200px", // Fixed height for dropdown
      overflowY: "auto", // Makes it scrollable
    }),
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="my-3">
            <div className="px-2 d-flex">
              <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                <ArrowBackIosIcon
                  width={2}
                  className="arrow-child"
                  onClick={handleCancleClick}
                />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                New Campaign
              </h5>
            </div>
          </div>

          {/* First Card */}
          <Card className="card-style">
            <Card.Body>
              <form onSubmit={handleSubmit}>
                <label
                  className="text-primary"
                  style={{ fontSize: 16, fontWeight: "600" }}
                >
                  NEW CAMPAIGN
                </label>
                <Row>
                  <Col md="6" className="mb-3">
                    <Col md="12" className="mb-3 mt-3">
                      <Form.Group>
                        <label className="label-style">
                          Customer name <span className="valid"> *</span>
                        </label>
                        <Select
                          className="mt-0"
                          options={customerOptions}
                          onChange={handleCustomerSelect}
                          placeholder="Select Customer"
                          styles={customStyles}
                          isClearable
                          value={null} // Keep empty to allow multiple selections
                        />
                      </Form.Group>
                    </Col>
                    <Col className="mb-3" md="12">
                      <Form.Group className="d-flex flex-column new-campaign-date">
                        <label className="label-style">
                          Date <span className="valid"> *</span>
                        </label>
                        <DatePicker
                          selected={selectedDate}
                          onChange={(date) => setSelectedDate(date)}
                          className="form-control"
                          dateFormat="dd-MM-yyyy"
                          placeholderText="Select date"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="12" className="mb-3">
                      <Form.Group>
                        <label className="label-style">Title</label>
                        <Form.Control
                          className="border border-1"
                          placeholder="Title"
                          name="title"
                          type="text"
                        />
                      </Form.Group>
                    </Col>
                  </Col>
                  <Col md="6" className="mb-3">
                    {selectedCustomers.length > 0 && (
                      <div className="p-3 selected-customer-section mt-4">
                        <h6 className="mb-3">Selected Customers</h6>
                        <div
                          className="d-flex flex-column flex-wrap gap-2"
                          style={{
                            maxHeight: "200px", // Fixed height for selected customers
                            overflowY: "auto", // Makes it scrollable
                            paddingRight: "10px", // Add some padding for the scrollbar
                          }}
                        >
                          {selectedCustomers.map((customer) => (
                            <div
                              key={customer.id}
                              className="bg-light rounded p-2 d-flex align-items-center mb-1"
                            >
                              <span className="mx-2">{customer.name}</span>
                              <button
                                type="button"
                                className="btn btn-sm p-0 border-0 mx-2"
                                onClick={() => removeCustomer(customer.id)}
                              >
                                <FontAwesomeIcon icon={faTimes} />
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className="mt-4">
                  <h6 className="text-primary mb-4 mx-4">Upload Attachments</h6>
                  <Card className="p-3 mb-4 mx-3 attachments-card">
                    {fileBlocks.map((block, index) => (
                      <div key={block.id} className="mb-4">
                        <Form.Control
                          className="form-control file-input-container"
                          type="file"
                          accept=".jpg,.jpeg,.png,.pdf"
                          onChange={(e) =>
                            handleFileChange(block.id, e.target.files[0])
                          }
                        />

                        {/* Preview Section */}
                        {block.preview && (
                          <div className="mt-3">
                            <img
                              src={block.preview}
                              alt="Preview"
                              className="file-preview-image"
                            />
                          </div>
                        )}

                        {/* File Name Section for PDFs */}
                        {block.name && (
                          <div className="mt-2 text-secondary">
                            <strong>Selected File:</strong> {block.name}
                          </div>
                        )}

                        {/* Remove Button */}
                        {index > 0 && (
                          <Button
                            variant="outline-danger"
                            className="mt-2"
                            onClick={() => handleRemoveFileBlock(block.id)}
                          >
                            Remove
                          </Button>
                        )}

                        {/* Add Button */}
                        {index === fileBlocks.length - 1 && (
                          <Button
                            variant="outline-secondary"
                            className="mt-2"
                            onClick={handleAddFileBlock}
                          >
                            Add More
                          </Button>
                        )}
                      </div>
                    ))}
                  </Card>
                </Row>

                <Row>
                  <Col md="12" className="mb-3">
                    <Form.Group>
                      <label className="label-style">Description</label>
                      <Editor
                        initialValue=""
                        apiKey="4h8oo2lv4rl9gi28mb7hv90bwsf4k369lnf0gmxragfpofwj"
                        init={{
                          branding: false,
                          height: 300,
                          menubar: true,
                          plugins:
                            "print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
                          toolbar:
                            "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                          image_advtab: true,
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col md="12">
                    <div className="border rounded p-3">
                      <h6 className="mb-3">Communication Methods</h6>
                      <div className="d-flex gap-4">
                        <Form.Check
                          type="checkbox"
                          id="whatsapp"
                          className="d-flex align-items-center gap-2 mx-4 my-1 custom-checkbox"
                          label={
                            <div className="d-flex align-items-center gap-2">
                              <WhatsappLogo
                                size={24}
                                className="text-success"
                              />
                              <span>WhatsApp</span>
                            </div>
                          }
                          checked={communicationMethods.whatsapp}
                          onChange={(e) =>
                            setCommunicationMethods({
                              ...communicationMethods,
                              whatsapp: e.target.checked,
                            })
                          }
                        />

                        <Form.Check
                          type="checkbox"
                          id="sms"
                          className="d-flex align-items-center gap-2 mx-4 my-1 custom-checkbox"
                          label={
                            <div className="d-flex align-items-center gap-2">
                              <MessageCircle
                                size={24}
                                className="text-primary"
                              />
                              <span>SMS</span>
                            </div>
                          }
                          checked={communicationMethods.sms}
                          onChange={(e) =>
                            setCommunicationMethods({
                              ...communicationMethods,
                              sms: e.target.checked,
                            })
                          }
                        />

                        <Form.Check
                          type="checkbox"
                          id="email"
                          className="d-flex align-items-center gap-2 mx-4 my-1 custom-checkbox"
                          label={
                            <div className="d-flex align-items-center gap-2">
                              <Mail size={24} className="text-danger" />
                              <span>Email</span>
                            </div>
                          }
                          checked={communicationMethods.email}
                          onChange={(e) =>
                            setCommunicationMethods({
                              ...communicationMethods,
                              email: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

                <div className="d-flex justify-content-end mt-2">
                  <button
                    type="button"
                    className="btn-1 border border-2 border-primary mt-0 me-2"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn-3 btn btn-primary mt-0">
                    Create
                  </button>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default NewCampaign;
