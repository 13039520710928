import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { AES, enc } from "crypto-js";
import { BASE_URL } from "../CommonApi";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Alert from "@mui/material/Alert";
import Select from "react-select";
import { Card, Form, Row, Col, Button, Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  faUser,
  faBuilding,
  faBriefcase,
  faCalendarAlt,
  faPhone,
  faEnvelope,
  faTint,
  faIndianRupeeSign,
  faFilePdf,
  faHome,
  faLock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EmployeeNew({ navigation }) {
  const encryptedUniqueId = localStorage.getItem("uniqueId1");
  const decryptedUniqueId = AES.decrypt(
    encryptedUniqueId,
    "1234567812345678"
  ).toString(enc.Utf8);
  const jwtToken = localStorage.getItem("jwtToken");
  const [formData, setFormData] = useState({
    employee_name: "",
    department: "",
    designation: "",
    date_of_joining: "",
    contact_number: "",
    alternate_contact: "",
    personal_email: "",
    dob: "",
    blood_group: "",
    salary: "",
    aadhar_card: null,
    pan_card: null,
    spouse_name: "",
    parent_name: "",
    bank_passbook: null,
    current_address: "",
    permanent_address: "",
    address_proof: null,
    highest_qualification: "",
    marksheets_pg: null,
    marksheets_grad: null,
    marksheets_12: null,
    certificates: null,
    previous_company: "",
    previous_designation: "",
    experience_letter: null,
    confirmation: false,
  });
  const handleNavigation = (key) => {
    if (navigation.state.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };
  const handleBankClick = () => {
    handleNavigation("bankacc");
  };
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setStartDate(new Date());
  }, []);
  const handleDate = (date) => {
    setStartDate(date);
  };
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? checked : type === "file" ? files[0] : value,
    }));
  };
  const handleCancleClick = () => {
    const confirmCancel = window.confirm("Are you sure you want to cancel?");
    if (confirmCancel) {
      // Code to handle cancellation
      handleBankClick();
    } else {
      // Code to handle cancellation not confirmed
      console.log("Cancellation not confirmed");
    }
  };

  const [loading, setLoading] = useState(false);
  const [employeeRole, setEmployeeRole] = useState("");

  const employeeRoleOptions = [
    { value: "HR", label: "HR" },
    { value: "Salesman", label: "Salesman" },
    { value: "Distributor", label: "Distributor" },
    { value: "Accountant", label: "Accountant" },
    { value: "Delivery", label: "Delivery" },
    { value: "Purchase", label: "Purchase" },
  ];

  const handleEmployeeRoleChange = (selectedOption) => {
    setEmployeeRole(selectedOption);
  };

  const [validationMessage, setValidationMessage] = useState("");

  if (loading) {
    return (
      <div className="center-div">
        <div className="spinner"></div>
      </div>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Add your form submission logic here
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="my-3">
              <div className="px-2 d-flex">
                <div className="cursor-pointer d-flex justify-content-center align-items-center back-button">
                  <ArrowBackIosIcon
                    width={2}
                    className="arrow-child"
                    onClick={handleCancleClick}
                  />
                </div>
                <h5
                  className="mt-1 mx-2 text-primary"
                  style={{ letterSpacing: "1px" }}
                >
                  Add New Employee
                </h5>
              </div>
            </div>
            <Card className="card-style">
              <Card.Body>
                <form onSubmit={handleSubmit}>
                  {/* Previous sections remain the same until Address Information */}
                  <h4 className="text-primary mb-4">Employee Form</h4>

                  {/* Personal Information Section */}
                  <h5 className="text-secondary mb-3">Personal Information</h5>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <Form.Control
                            type="text"
                            name="employee_name"
                            value={formData.employee_name}
                            onChange={handleChange}
                            required
                            placeholder="Enter Employee First Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </span>
                          <Form.Control
                            type="text"
                            name="department"
                            value={formData.department}
                            onChange={handleChange}
                            required
                            placeholder="Enter Employee Last Name"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Employee Role <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faBriefcase} />
                          </span>
                          <Form.Control
                            type="text"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            required
                            placeholder="Enter Designation"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Date of Joining <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            style={{ width: "7%" }}
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </span>
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            dateFormat="dd-MM-yyyy"
                            onChange={(date) => handleDate(date)}
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Contact Information */}
                  <h5 className="text-secondary mb-3 mt-4">
                    Contact Information
                  </h5>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Contact Number <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <Form.Control
                            type="tel"
                            name="contact_number"
                            value={formData.contact_number}
                            onChange={handleChange}
                            required
                            placeholder="Enter Contact Number"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Alternate Contact{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faPhone} />
                          </span>
                          <Form.Control
                            type="tel"
                            name="alternate_contact"
                            value={formData.alternate_contact}
                            onChange={handleChange}
                            required
                            placeholder="Enter Alternate Contact"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Personal Details */}
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Personal Email <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                          <Form.Control
                            type="email"
                            name="personal_email"
                            value={formData.personal_email}
                            onChange={handleChange}
                            required
                            placeholder="Enter Personal Email"
                          />
                        </div>
                      </Form.Group>
                    </Col>

                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Password<span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span
                            className="input-group-text"
                            style={{ width: "7%" }}
                          >
                            <FontAwesomeIcon icon={faLock} />
                          </span>
                          <Form.Control
                            type="text"
                            name="blood_group"
                            value={formData.blood_group}
                            onChange={handleChange}
                            required
                            placeholder="Enter Blood Group"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group>
                        <label className="label-style">
                          Address <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <span className="input-group-text">
                            <FontAwesomeIcon icon={faHome} />
                          </span>
                          <Form.Control
                            type="text"
                            name="blood_group"
                            value={formData.blood_group}
                            onChange={handleChange}
                            required
                            placeholder="Enter Blood Group"
                          />
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  {/* Submit Button */}
                  <Row>
                    <Col md="12">
                      <div className="d-flex justify-content-end mt-4">
                        <Button
                          variant="outline-secondary"
                          className="mx-2"
                          onClick={handleCancleClick}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="primary"
                          className="mx-2"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Submit Application
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default EmployeeNew;
