import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./OrderTracking.css";

const OrderTracking = ({ navigation }) => {
  const [activePopup, setActivePopup] = useState(null);
  const [showFollowUpModal, setShowFollowUpModal] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null); // Add state for DatePicker

  const [completedSteps, setCompletedSteps] = useState([]);
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  const handleStepCompletion = (currentStepIndex) => {
    // Always progress to the next step, but only if not already at the last step
    if (currentStepIndex < steps.length - 1) {
      setActiveStepIndex(currentStepIndex + 1);
    }

    // Mark the step as completed
    if (!completedSteps.includes(currentStepIndex)) {
      setCompletedSteps((prev) => [...prev, currentStepIndex]);
    }
  };

  const handleNavigation = (key) => {
    if (navigation?.state?.routes[navigation.state.index].key !== key) {
      navigation.navigate(key);
    }
  };

  const handleCancleClick = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to leave this page?"
    );
    if (confirmCancel) {
      handleNavigation("CRMCard");
    }
  };

  const messages = [
    {
      title: "Payment Reminder",
      content: "Kindly make the pending payment for invoice #INV12345.",
      date: "2024-01-20 09:00 AM",
    },
    {
      title: "Project Update Request",
      content: "Please provide an update on the progress of Project Phoenix.",
      date: "2024-01-21 02:30 PM",
    },
    {
      title: "Document Submission Follow-up",
      content: "We are still awaiting the signed agreement document.",
      date: "2024-01-22 11:15 AM",
    },
    {
      title: "Meeting Reminder",
      content:
        "Reminder: The quarterly review meeting is scheduled for tomorrow at 10:00 AM.",
      date: "2024-01-23 04:00 PM",
    },
  ];

  const files = [
    {
      fileName: "document1.pdf",
      uploadedBy: "John Doe",
      uploadDate: "2024-01-22",
    },
    {
      fileName: "report.xlsx",
      uploadedBy: "Jane Smith",
      uploadDate: "2024-01-21",
    },
    {
      fileName: "presentation.pptx",
      uploadedBy: "Michael Brown",
      uploadDate: "2024-01-20",
    },
  ];

  const steps = [
    {
      title: "Enquiry",
      icon: "info-circle",
      tooltip: "Step to collect customer enquiry",
    },
    {
      title: "Quotation",
      icon: "file-alt",
      tooltip: "Provide a detailed quotation",
    },
    {
      title: "Follow-Up",
      icon: "phone",
      tooltip: "Reach out for confirmation or feedback",
    },
    {
      title: "Purchase Order",
      icon: "shopping-cart",
      tooltip: "Confirm and place the purchase order",
    },
    {
      title: "Sales Order",
      icon: "file-invoice",
      tooltip: "Generate the sales order",
    },
    {
      title: "Stock Check",
      icon: "warehouse",
      tooltip: "Ensure availability of stock",
    },
    {
      title: "Dispatch",
      icon: "truck",
      tooltip: "Send out the order for delivery",
    },
    {
      title: "Payment",
      icon: "money-check-alt",
      tooltip: "Receive the payment for the order",
    },
    {
      title: "Breakage/Damage",
      icon: "exclamation-triangle",
      tooltip: "Handle any issues of breakage or damage",
    },
  ];

  // Modify the FilePopup component:

  const FileUploadStep = ({
    step,
    index,
    activeStepIndex,
    onStepComplete,
    completedSteps,
  }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const circleRef = React.useRef(null);

    const handleCircleClick = () => {
      if (circleRef.current) {
        const rect = circleRef.current.getBoundingClientRect();
        setActivePopup({
          index,
          position: {
            top: rect.top + window.scrollY,
            left: rect.left + window.scrollX,
            width: rect.width,
            height: rect.height,
          },
        });
      }
    };

    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      setSelectedFile(file);
    };

    const handleFileSubmit = () => {
      if (selectedFile) {
        // Complete the current step and move to the next
        onStepComplete(index);
        // Clear the selected file after submission
        setSelectedFile(null);
      }
    };

    const getCircleClassName = () => {
      if (index === activeStepIndex) return "active";
      if (completedSteps.includes(index)) return "completed";
      return "";
    };

    return (
      <div className="step-wrapper">
        <div className="step-content">
          <h6 className="step-title">{step.title}</h6>
          <div
            ref={circleRef}
            className={`step-circle ${getCircleClassName()}`}
            onClick={handleCircleClick}
          >
            <i className={`fas fa-${step.icon}`}></i>
            <div className="step-tooltip">{step.tooltip}</div>
          </div>

          <div className="file-upload-wrapper">
            <input
              type="file"
              id={`file-step-${index}`}
              className="file-upload"
              onChange={handleFileUpload}
            />
            <div className="d-flex">
              <label
                htmlFor={`file-step-${index}`}
                className="file-upload-btn mx-2"
              >
                <i className="fas fa-upload"></i>
              </label>
              {index === 2 && (
                <label
                  className="file-upload-btn"
                  onClick={() => setShowFollowUpModal(true)}
                >
                  <i className="fas fa-comments"></i>
                </label>
              )}
            </div>
            {selectedFile && (
              <button
                className={`btn btn-xs ${selectedFile ? "active" : ""}`}
                onClick={handleFileSubmit}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const FilePopup = ({ position, onClose }) => (
    <div
      className="action-popup show"
      style={{
        position: "absolute",
        top: position ? `${position.top + position.height + 10}px` : "0",
        left: position ? `${position.left + position.width / 2 - 290}px` : "0",
      }}
    >
      <button
        className="close-popup-btn"
        onClick={onClose}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          background: "none",
          border: "none",
          fontSize: "20px",
          cursor: "pointer",
        }}
      >
        &times;
      </button>
      <div className="fetched-files">
        {files.map((file, index) => (
          <div className="fetched-file" key={index}>
            <div className="file-info">
              <div className="file-name">
                <i className="fas fa-file"></i>
                <span>{file.fileName}</span>
              </div>
              <div className="upload-info">
                <i className="fas fa-user"></i>
                <span>{file.uploadedBy}</span>
              </div>
              <div className="upload-date">
                <i className="fas fa-calendar"></i>
                <span>{file.uploadDate}</span>
              </div>
            </div>
            <div className="popup-actions">
              <Button variant="success" className="mx-2">
                <i className="fas fa-eye"></i> Preview
              </Button>
              <Button variant="primary" className="mx-2">
                <i className="fas fa-download"></i> Download
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <Container fluid className="py-4">
      <Row className="justify-content-center">
        <Col lg={12} md={12}>
          <div className="my-3">
            <div className="px-2 d-flex align-items-center">
              <div className="back-button" onClick={handleCancleClick}>
                <ArrowBackIosIcon size={20} className="arrow-child" />
              </div>
              <h5
                className="mt-1 mx-2 text-primary"
                style={{ letterSpacing: "1px" }}
              >
                New Campaign
              </h5>
            </div>
          </div>

          <Card className="card-style">
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div>
                  <h5>
                    Enquiry ID: <span className="text-muted">ENQ001</span>
                  </h5>
                  <h5>
                    Customer Name: <span className="text-muted">John Doe</span>
                  </h5>
                </div>
                <div>
                  <Button
                    variant="primary"
                    className="mx-2"
                    onClick={() => setShowMessagesModal(true)}
                  >
                    Follow-Up Messages
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => window.history.back()}
                  >
                    Back
                  </Button>
                </div>
              </div>

              <div className="text-center">
                <div
                  className="main-content-label mb-4"
                  style={{ fontSize: "24px", fontWeight: "bold" }}
                >
                  Add Enquiry Steps
                </div>
                <div className="progress-snake">
                  {steps.map((step, index) => (
                    <FileUploadStep
                      key={index}
                      step={step}
                      index={index}
                      activeStepIndex={activeStepIndex}
                      onStepComplete={handleStepCompletion}
                      completedSteps={completedSteps}
                    />
                  ))}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {activePopup !== null && (
        <FilePopup
          position={activePopup.position}
          onClose={() => setActivePopup(null)}
        />
      )}

      <Modal
        show={showFollowUpModal}
        onHide={() => setShowFollowUpModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Follow-up Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Message Title</Form.Label>
              <Form.Control
                type="text"
                className="message-title"
                placeholder="Enter Message Title"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 w-100">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="form-control message-box"
                name="current_address"
                required
                placeholder="Enter Your Message"
              />
            </Form.Group>
            <Form.Group className="mb-3 next-followup-date">
              <Form.Label>Next Follow-up Date</Form.Label>
              <DatePicker
                selected={selectedDate}
                onChange={(date) => setSelectedDate(date)}
                className="form-control"
                dateFormat="dd-MM-yyyy"
                placeholderText="Next Follow-Up Date"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowFollowUpModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showMessagesModal}
        onHide={() => setShowMessagesModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Follow-Up Messages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled">
            {messages.map((msg) => (
              <li className="mb-3 pb-3 border-bottom">
                <h6>&bull;&nbsp;{msg.title}</h6>
                <p> {msg.content}</p>
                <small className="text-muted">{msg.date}</small>
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowMessagesModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default OrderTracking;
